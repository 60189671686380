<template>
    <b-card title="Edit Option Warranty">
      <validation-observer :slim="true" ref="warrantyValidation">

        <b-col cols="12">
          <label>Name</label>
          <b-form-input placeholder="Option" v-model="optionWarranty.name" disabled></b-form-input>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="Duration (Month)"
            rules="required|numeric|min:1|max:2|min_value:12"
            tag="div"
            >
          <label>Duration (Month)</label>
          <b-form-input number placeholder="Extend Month" v-model="optionWarranty.month" :state="errors.length > 0 ? false:null"></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button variant="success" :disabled="onSubmit" @click="doUpdateOptionWarranty">Update</b-button>
        </b-col>
      </validation-observer>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, digits, min,min_value,max } from '@validations';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
    props: ["id"],
    data() {
        return {
            optionWarranty: {},
            onSubmit:false
        }
    },
    mounted() {
        this.getOptionWarranty().then(async (data) => {
            // // BAD !!!
            // let id = this.$route.params.id;
            // let warranty = data.filter((w) => {
            //     if (w._id === id) {
            //         return true;
            //     }

            //     return false;
            // })
            var temp = data.find((x)=>{
                return x._id==this.id
            })
            if(temp==undefined){
                this.$bvToast.toast("Option Not found", {
                    title: "Option not found",
                    variant: "Danger",
                    solid: true,
                });
                await sleep(1000);
                this.$router.go(-1);
            }
            this.optionWarranty = temp 

        })
    },
    methods: {
        ...mapActions({
            getOptionWarranty: 'warranty/getOptionWarranty',
            updateOptionWarranty: 'warranty/updateOptionWarranty'
        }),
        doUpdateOptionWarranty() {
          if (this.onSubmit) {
            return;
          }
          this.$refs.warrantyValidation.validate().then((validationResult) => {
            if(validationResult){
              this.onSubmit = true

              this.updateOptionWarranty(this.optionWarranty).then(async () => {
                this.$bvToast.toast("Successfully Updated Option", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1000);
                this.$router.go(-1);
            }).catch((e) => {
                console.error(e)
                let message =(e.response.data.errors[0].message)?e.response.data.errors[0].message:"Update Option Failed"
                this.$bvToast.toast(message, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
            }else{
              this.$bvToast.toast('Please fill the form first', {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }
            
          }).catch((e) => {
            console.log(e)
          })
            
        }
        
    }
}
</script>